
































































































// @ is an alias to /src
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import hashing from '../data/hashing';

export default Vue.extend({
  name: 'Hashing',
  metaInfo(): MetaInfo {
    return {
      title: 'Hashing',
      meta: [
        { name: 'Hashing', content: 'Enter some text, select 1 or more hashing algorithms and hash the text' },
        { property: 'og:title', content: 'Coderpunk tools Hashing' },
        { property: 'og:site_name', content: 'Coderpunk tools' },
        { property: 'og:description', content: 'Enter some text, select 1 or more hashing algorithms and hash the text' },
        { property: 'og:type', content: 'webapp'},
        { property: 'og:url', content: 'https://tools.coderpunk.tech/#/hashing' },
        { property: 'og:image', content: '@/assets/Coderpunk.png' }    
      ]
    }
  },
  data: () => ({
    algos: hashing.algos,
    selectedAlgos: [],
    text: '',
    salt: '',
    results: [{name: '', hash: ''}]
  }),
  mounted() {
    this.results = [];
  },
  methods: {
    unselectAlgo(item: string): void {
      this.selectedAlgos = this.selectedAlgos
        .filter((algo) => algo !== item);
    },
    hash() {
      // for each selected algo, run the hash function and display each result
      this.results = this.selectedAlgos.map((selectedAlgo) => {
        return {
          name: selectedAlgo,
          hash: hashing.hash(selectedAlgo, this.text, this.salt)
        }
      });
    },
    // copyHash(result: any): void {
    //   if (document) {
    //     const el = document.createElement('textarea');  
    //     el.value = result.hash;                               
    //     el.setAttribute('readonly', '');                
    //     el.style.position = 'absolute';                     
    //     el.style.left = '-9999px';                      
    //     document.body.appendChild(el);                  
    //     const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
    //     el.select();                                    
    //     document.execCommand('copy');                   
    //     document.body.removeChild(el);                  
    //     if (selected) {                                 
    //       document.getSelection().removeAllRanges();    
    //       document.getSelection().addRange(selected);
    //     }
    //   }
    // }
  }
})
